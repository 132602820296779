<template>
    <div>
        <DataTable class="table"
                   :ajax="url"
                   :columns="columns"
                   :id="tableId"
                   :options="{
                initComplete: dataTableFilters,
                serverSide: true,
                processing: true,
                fixedHeader: true,
                lengthChange: true,
                ordering: true,
                searching: true,
                stateSave: true,
                displayStart: 0,
                lengthMenu: [[100, 250, 500, 1000], [100, 250, 500, 1000]],
                orderCellsTop: true,
                pageLength: 100,
                pagingType: 'full_numbers',
                search: {
                    caseInsensitive: true,
                    regex: true,
                    smart: true
                },
                searchDelay: 1000,
                stateDuration: (60 * 60),       // 60 seconds * 60 minutes = 1 hour = 3600s
                language: {
                    decimal: ',',
                    emptyTable: 'Geen data beschikbaar',
                    info: 'Toon _START_ tot _END_ van _TOTAL_ regels',
                    infoEmpty: 'Geen regels om te tonen',
                    infoFiltered: '(gefilterd van _MAX_ regels)',
                    infoPostFix: '',
                    thousands: '.',
                    lengthMenu: 'Toon _MENU_ regels',
                    loadingRecords: 'Laden...',
                    processing: '',
                    search: 'Zoeken: ',
                    zeroRecords: 'Geen resultaten gevonden',
                    paginate: {
                        first: 'Eerste',
                        last: 'Laatste',
                        next: 'Volgende',
                        previous: 'Vorige'
                    }
                }
            }"
                   ref="datatable">
            <thead>
            <tr>
                <th v-for="idx in columns" :key="idx">
                    {{ idx.title }}
                </th>
            </tr>
            <tr>
                <td v-for="idx in columns" :key="idx"
                    :class="{'filter-input': idx.filterInput, 'filter-select': idx.filterDropdown, 'filter-select-branches': idx.filterBranches}">
                </td>
            </tr>
            </thead>
            <tbody></tbody>
        </DataTable>
    </div>
</template>

<script>
import {ref} from 'vue'
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net'
import jQuery from "jquery";
import Multiselect from "@vueform/multiselect";
import * as bootstrap from 'bootstrap';

// Import Select 2 from library
import("select2").then(m => m.default());
// no point for naming
import select2 from 'select2'
//import 'select2/dist/css/select2.css'
select2();

DataTable.use(DataTablesCore)

const table = ref()
let tableSearchTimer;

export default {
    components: {
        DataTable,
        Multiselect
    },
    props: [
        'url',
        'columns',
        'tableId',
        'branchesList'
    ],
    data() {
        return {
            table: null,
            selectedBranches: null
        }
    },
    methods: {
        dataTableFilters() {
            let _vue = this;
            // Events
            jQuery('body').delegate('.linkitem-collapse-toggle', 'click', function(e) {
                e.preventDefault();
                e.stopPropagation();

                let row = jQuery(this).parents('tr', 0);
                jQuery('.linkitem-expandable', row).removeClass('collapsed');
                jQuery('.linkitem-collapse-toggle', row).remove();
            });

            // let table = this.$refs.datatable.dt;
            let _element = jQuery('#' + this.tableId);
            if (typeof _element.dataTable().api == 'function') {
                let table = _element.dataTable().api();
                let vue = this;
                let filterElement = jQuery('#' + this.tableId + '_filter')

                jQuery('input', filterElement).off('input')
                    .off('keyup')
                    .off('keydown')
                    .unbind()// Unbind previous default bindings
                    .bind("keyup", function() { // Bind our desired behavior
                        console.log('keyup event')
                        // Call the API search function
                        clearTimeout(tableSearchTimer)
                        tableSearchTimer = setTimeout(function(el, table) {
                            console.log('searching')
                            table.search(el.value).draw()
                            console.log('search finished')
                        }, 500, this, table)
                        return;
                    });

                // let container = document.getElementById('toggle-column-container');
                let container = document.createElement('div')
                container.classList = 'actions mb-3'
                let tableParent = table.table().node().parentNode
                tableParent.insertBefore(container, table.table().node())

                let tableId = _element.attr('id') + window.location.pathname
                let visibility = JSON.parse(localStorage.getItem(tableId + '/columnVisibility')) || {};

                let dropdown = document.createElement('div');
                dropdown.classList.add('dropdown');
                container.appendChild(dropdown);

                let dropdownButton = document.createElement('button');
                dropdownButton.classList.add('btn', 'btn-primary', 'dropdown-toggle', 'mb-3');
                dropdownButton.setAttribute('type', 'button');
                dropdownButton.setAttribute('id', 'dropdownColumnButton' + table.table().node().id);
                dropdownButton.setAttribute('data-bs-toggle', 'dropdown');
                dropdownButton.setAttribute('aria-expanded', 'false');
                dropdownButton.textContent = 'Toggle Kolommen';
                dropdown.appendChild(dropdownButton);

                let dropdownMenu = document.createElement('ul');
                dropdownMenu.classList.add('dropdown-menu');
                dropdownMenu.setAttribute('aria-labelledby', 'dropdownColumnButton' + table.table().node().id);
                dropdown.appendChild(dropdownMenu);

                let clearFiltersButton = document.createElement('button');
                clearFiltersButton.classList.add('btn', 'btn-outline-secondary', 'ml-2');
                clearFiltersButton.textContent = 'Zoekfilters verwijderen';
                container.appendChild(clearFiltersButton);

                clearFiltersButton.addEventListener('click', function() {
                    clearAllFilters();
                });

                function addFiltersToColumn(column, _element, originalIndex, header) {
                    let columnIndex = table.column(originalIndex).index('visible');

                    // Remove existing filters to avoid duplication
                    jQuery("thead tr td", _element).eq(columnIndex).empty();

                    if (jQuery("thead tr td", _element).eq(columnIndex).hasClass('filter-input')) {
                        var input = jQuery('<input type="text" class="form-control form-control-sm" />')
                            .appendTo(jQuery("thead tr td", _element).eq(columnIndex))
                            .on("keyup", function () {
                                var value = jQuery(this).val();
                            localStorage.setItem(tableId + '/filterInput/' + header.innerText, value);
                                column.search(value).draw();
                            }).val(column.search());
                
                        var savedValue = localStorage.getItem(tableId + '/filterInput/' + header.innerText);
                        if (savedValue) {
                            input.val(savedValue);
                            column.search(savedValue).draw();
                        }
                    }


                    if (jQuery("thead tr td", _element).eq(columnIndex).hasClass('filter-select')) {
                        var select = jQuery('<select class="form-select form-select-sm"><option value=""></option></select>')
                            .appendTo(jQuery("thead tr td", _element).eq(columnIndex))
                            .on('change', function () {
                                let val = jQuery.fn.dataTable.util.escapeRegex(
                                    jQuery(this).val()
                                );

                                column.search(val).draw();
                            })

                        column.data().unique().sort().each(function(d, j) {
                            let colID = makeid(12);
                            let selected = column.search() == stripHtml(d) ? ' selected="selected"' : ''
                            select.append('<option id="' + colID + '" value="' + stripHtml(d) + '"' + selected + '>' + stripHtml(d) + '</option>')
                        })
                    }
                    
                    if (jQuery("thead tr td", _element).eq(columnIndex).hasClass('filter-select-branches')) {
                        var select = jQuery('<select class="form-select form-select-sm select2" style="width: 200px"><option value=""></option></select>')
                            .appendTo(jQuery("thead tr td", _element).eq(columnIndex))
                            .on('change', function () {
                                clearTimeout(tableSearchTimer)
                                tableSearchTimer = setTimeout(function(el) {
                                    let val = jQuery.fn.dataTable.util.escapeRegex(
                                        jQuery(el).val()
                                    );

                                    column.search(val).draw();
                                }, 500, this)
                            });
                        jQuery('.select2').select2();

                        for (const [d, j] of Object.entries(_vue.branchesList)) {
                            let selected = column.search() == vue.stripHtml(j) ? ' selected="selected"' : ''
                            select.append('<option id="branche_' + j + '" value="' + vue.stripHtml(j) + '"' + selected + '>' + vue.stripHtml(j) + '</option>')

                        }
                    }
                }

                // Add filtering
                table.columns().every(function(index) {
                    let column = this;
                    let header = column.header(); 
                    let originalIndex = index;

                    let menuItem = document.createElement('li');
                    let link = document.createElement('a');
                    link.classList.add('dropdown-item');
                    link.dataset.column = originalIndex;

                    let checkbox = document.createElement('input');
                    checkbox.type = 'checkbox';
                    checkbox.style.marginRight = '5px';
                    checkbox.dataset.column = originalIndex;

                    link.appendChild(checkbox);
                    link.appendChild(document.createTextNode(header.textContent));
                    menuItem.appendChild(link);
                    dropdownMenu.appendChild(menuItem);

                    link.addEventListener('click', function(e) {
                        e.preventDefault();
                        if (e.target.tagName !== 'INPUT') {
                            let checkbox = link.querySelector('input[type=checkbox]');
                            toggleColumnVisibility(checkbox, column, originalIndex);
                            
                        }
                    });

                    checkbox.addEventListener('click', function(e) {
                        e.stopPropagation();
                        toggleColumnVisibility(this, column, originalIndex);
                        
                    })

                    let isVisible = visibility[originalIndex];
                    if (isVisible !== undefined) {
                        column.visible(isVisible);
                        checkbox.checked = isVisible;
                    } else {
                        checkbox.checked = column.visible();
                    }

                    if (column.visible()) {
                        let columnIndex = table.column(originalIndex).index();
                        addFiltersToColumn(column, _element, columnIndex, header);

                    }
                })

                const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
                dropdownElementList.forEach(dropdownToggleEl => new bootstrap.Dropdown(dropdownToggleEl));

                function toggleColumnVisibility(checkbox, column, originalIndex) {
                    let isVisible = !column.visible();
                    column.visible(isVisible);

                    visibility[originalIndex] = isVisible;
                    localStorage.setItem(tableId + '/columnVisibility', JSON.stringify(visibility));

                    checkbox.checked = isVisible;

                    table.columns().every(function(index) {
                        let column = this;
                        let header = column.header();
                        let originalIndex = index;
                        if (column.visible()) {
                            addFiltersToColumn(column, _element, originalIndex, header);
                        }
                    });
                }

                function clearAllFilters() {
                    table.columns().every(function() {
                        let column = this;
                        column.search('').draw();
                        localStorage.removeItem(tableId + '/filterInput/' + column.header().innerText);
                    });

                    jQuery("thead tr td", _element).each(function() {
                        jQuery(this).empty();
                    });

                    table.columns().every(function(index) {
                        let column = this;
                        let header = column.header();
                        let originalIndex = index;
                        if (column.visible()) {
                            addFiltersToColumn(column, _element, originalIndex, header);
                        }
                    });
                }
            }
        },



        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
        stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent.trim() || tmp.innerText.trim() || "";
        }
    }
}
</script>

<style lang="scss">
.datatable {
    position: relative;
}

div.dataTables_processing {
    background: rgba(255,255,255,0.8);
    position: absolute;
    top: 26px;
    left: 100px;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;

    > div:last-child {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
