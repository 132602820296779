<template>
    <div class="loading" v-if="isLoading()">

    </div>
    <div class="card" v-if="!isLoading()">
        <div class="card-body">
            <div class="alert alert-danger" v-if="validation.errors.length > 0">
                <ul>
                    <li v-for="msg in validation.errors" :key="msg">{{ msg }}</li>
                </ul>
            </div>

            <div class="mb-3">
                <label for="websitesId" id="websitesIdLabel" class="form-label">Website</label>
                <multiselect
                    v-model="websitesId"
                    :options="websites"
                    :searchable="true"
                    id="websitesId"
                    :class="{'validation-error': this.validation.errorFields.includes('websitesId')}"
                    v-if="subscriptionsId == null"
                    @change="validateForm()"
                >
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon">&nbsp;{{ value.label }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon">&nbsp;{{ option.label }}
                    </template>
                </multiselect>

                <span class="form-control d-flex align-items-center gap-1" style="height: 38px;" v-if="subscriptionsId != null">
                    <img :src="selectedWebsite.favicon">&nbsp;{{ selectedWebsite.name }}
                </span>

                <div class="alert alert-danger" v-if="errors.websites_id">
                    <ul class="mb-0">
                        <li v-for="error in errors.websites_id" :key="error" class="mb-0">{{ error }}</li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="mb-3 col-6">
                    <label for="managersId" id="managersIdLabel" class="form-label">Manager</label>
                    <multiselect
                        v-model="managersId"
                        :options="users"
                        :searchable="true"
                        id="managersId"
                        :class="{'validation-error': this.validation.errorFields.includes('managersId')}"
                        @change="validateForm()"
                    ></multiselect>
                    <div class="alert alert-danger" v-if="errors.managers_id">
                        <ul class="mb-0">
                            <li v-for="error in errors.managers_id" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-3 col-6" v-if="subscriptionsType != 'migrate'">
                    <label for="linkbuildersId" id="linkbuidersIdLabel" class="form-label">Linkbuilder</label>
                    <multiselect
                        v-model="linkbuildersId"
                        :options="users"
                        :searchable="true"
                        id="linkbuildersId"
                        :class="{'validation-error': this.validation.errorFields.includes('linkbuildersId')}"
                        @change="validateForm()"
                    ></multiselect>

                    <div class="alert alert-danger" v-if="errors.linkbuilders_id">
                        <ul class="mb-0">
                            <li v-for="error in errors.linkbuilders_id" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="mb-3 col-6">
                    <label for="subscriptionsType" id="subscriptionsTypeLabel" class="form-label">Type
                        abonnement</label>
                    <multiselect
                        v-model="subscriptionsType"
                        :options="subscriptionsTypes"
                        :searchable="true"
                        id="subscriptionsType"
                        :class="{'validation-error': this.validation.errorFields.includes('subscriptionsType')}"
                        @change="validateForm()"
                    ></multiselect>

                    <div class="alert alert-danger" v-if="errors.type">
                        <ul class="mb-0">
                            <li v-for="error in errors.type" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="mb-3 col-6">
                    <label for="togglProjectId" id="togglProjectIdLabel" class="form-label">Toggl project(en)</label>
                    <multiselect
                        v-model="togglProjectId"
                        id="togglProjectId"

                        :options="togglProjects"
                        :searchable="true"
                        :class="{'validation-error': this.validation.errorFields.includes('togglProjectId')}"
                        :mode="'multiple'"
                        :max="5"
                        :limit="500"
                        :loading="togglProjects == null"
                        :clear-on-search="false"
                        :clear-on-blur="true"
                        :clear-on-select="false"
                        :close-on-deselect="false"
                        :close-on-select="false"

                        @change="validateForm()"
                    ></multiselect>

                    <div class="d-flex" style="margin-top: 16px; gap: 16px;">
                        <div v-for="id in togglProjectId" :key="id">
                            <span class="badge bg-primary">{{ getProjectById(id).label }}</span>
                        </div>
                    </div>

                    <span class="text-muted" v-if="!showNewLinkpartnerForm">
                        Staat het project er niet tussen? <a href="#" @click.prevent="showNewProjectForm = true">Maak hier een nieuw project!</a>
                    </span>

                    <!-- <div class="alert alert-danger" v-if="errors != {} && errors.toggl_projects">{{ errors.toggl_projects }}</div> -->

                    <div v-if="showNewProjectForm" class="mb-3">
                        <label for="newProjectName">Nieuw projectnaam</label>
                        <input v-model="newProjectName" type="text" class="form-control" id="newProjectName" />
                        <button @click.prevent="addNewProject" class="btn btn-primary mt-2">Toevoegen</button>
                    </div>

                    
                    <div class="alert alert-danger" v-if="errors.toggl_projects">
                        <ul class="mb-0">
                            <li v-for="error in errors.toggl_projects" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mb-3" v-if="subscriptionsType != 'migrate'">
                <label class="form-label">Type budget</label>
                <div class="btn-group d-block" role="group" aria-label="Type budget">
                    <button type="button" class="btn btn-outline-primary"
                            :class="{'active': budgetType == 'monthly'}"
                            @click="this.budgetType = 'monthly'">Maandelijks
                    </button>
                    <button type="button" class="btn btn-outline-primary"
                            :class="{'active': budgetType == 'fixed'}"
                            @click="this.budgetType = 'fixed'">Vast
                    </button>
                </div>
            </div>

            <div class="mb-3" v-if="subscriptionsType == 'migrate'">
                <label class="form-label">Type migratie</label>
                <div class="btn-group d-block" role="group" aria-label="Type migratie">
                    <button type="button" class="btn btn-outline-primary"
                            :class="{'active': websiteType == 'website'}"
                            @click="this.websiteType = 'website'">Website
                    </button>
                    <button type="button" class="btn btn-outline-primary"
                            :class="{'active': websiteType == 'webshop'}"
                            @click="this.websiteType = 'webshop'">Webshop
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="mb-3 col-6">
                    <label for="start_date" class="form-label" id="start_dateLabel">Start Datum</label>
                    <input type="date" name="start_date" id="start_date" class="form-control"
                           aria-labelledby="start_dateLabel" v-model="startDate"
                           :class="{'validation-error': this.validation.errorFields.includes('startDate')}"
                           @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.start_date">
                        <ul class="mb-0">
                            <li v-for="error in errors.start_date" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>

                    <p class="text-muted mt-3" v-if="subscriptionsType != 'migrate'">
                        Wanneer je de startdatum aanpast, pas je daarmee automatisch ook het totale budget voor deze
                        klant aan.
                    </p>
                </div>

                <div class="mb-3 col-6">
                    <label for="end_date" class="form-label" id="end_dateLabel">Eind Datum</label>
                    <input type="date" name="end_date" id="end_date" class="form-control"
                           aria-labelledby="end_dateLabel" v-model="endDate"
                           :class="{'validation-error': this.validation.errorFields.includes('endDate')}"
                           @change="validateForm()">

                    <div class="alert alert-danger" v-if="errors.end_date">
                        <ul class="mb-0">
                            <li v-for="error in errors.end_date" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row" v-if="subscriptionsType != 'migrate'">
                <div class="mb-3 col-6">
                    <label for="budget" class="form-label" id="budgetLabel">Budget <span v-if="subscriptionsType == 'media'">in euro's</span></label>
                    <div class="input-group">
                        <span class="input-group-text" v-if="subscriptionsType == 'media'">€</span>
                        <input type="number" name="budget" id="budget" class="form-control" aria-labelledby="budgetLabel"
                               v-model="budget" step="0.1"
                               :class="{'validation-error': this.validation.errorFields.includes('budget')}"
                               @change="validateForm()">
                        <span class="input-group-text" v-if="subscriptionsType != 'media'">uren</span>
                    </div>

                    <div class="alert alert-danger" v-if="errors.budget">
                        <ul class="mb-0">
                            <li v-for="error in errors.budget" :key="error" class="mb-0">{{ error }}</li>
                        </ul>
                    </div>

                    <p class="alert alert-danger mt-3">
                        Wanneer je het budget van een abonnement aanpast, wordt dit met terugwerkende
                        kracht doorgevoerd. Heb je een nieuw budget met de klant afgesproken, maak dan een nieuw
                        abonnement aan.
                    </p>
                </div>
            </div>

            <div class="row mb-3" v-if="subscriptionsType != 'migrate'">
                <div class="col-12">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="onHold" v-model="onHold" aria-labelledby="onHoldLabel">
                        <label class="form-check-label" for="onHold" id="onHoldLabel">Abonnement on hold</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button type="submit" class="btn btn-primary" @click="submitForm()">
                <i class="las la-save"></i> Opslaan
            </button>

            <div class="alert alert-danger mt-3 mb-0" v-if="hasErrors">
                Er zijn fouten opgetreden bij het opslaan van het formulier.
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import axios from 'axios'

export default {
    components: {
        Multiselect
    },
    data() {
        return {
            'subscriptionsId': null,
            'websites': [{
                value: '',
                label: 'Wordt geladen...'
            }],
            'users': [],
            'websitesId': null,
            'linkbuildersId': null,
            'managersId': null,
            'budgetType': 'monthly',
            'websiteType': null,
            'subscriptionsType': null,
            'subscriptionsTypes': [],
            'startDate': null,
            'endDate': null,
            'togglProjectId': [],
            'togglProjects': [],
            'newProjectName': '',
            'budget': 0,
            'mediaMargin': 30,
            'customersId': null,
            'validation': {
                'errors': [],
                'errorFields': []
            },
            'errors': {},
            'hasErrors': false,
            'selectedWebsite': {},
            'onHold': false,
            'showNewProjectForm': false
        }
    },
    watch: {
        subscriptionsType(newType) {
            if (newType === 'migrate') {
                this.budgetType = 'fixed';
            }
        }
    },
    methods: {
        submitForm() {
            this.validateForm()

            if (this.validation.errors.length == 0) {
                let data = {
                    'websites_id': this.websitesId,
                    'linkbuilders_id': this.linkbuildersId,
                    'managers_id': this.managersId,
                    'budget_type': this.budgetType,
                    'website_type': this.websiteType,
                    'type': this.subscriptionsType,
                    'start_date': this.startDate,
                    'end_date': this.endDate,
                    'budget': this.budget,
                    'toggl_projects': this.togglProjectId,
                    'on_hold': this.onHold ? 1 : 0
                }

                this.errors = {}
                this.hasErrors = false
                if (this.subscriptionsId != null) {
                    axios.patch('/api/subscriptions/' + this.subscriptionsId, data).then(response => {
                        window.location = '/subscription/' + response.data.type + '/website/' + response.data.websites_id;
                    }).catch(error => {
                        this.errors = error.response.data.errors
                        this.hasErrors = true
                    })
                } else {
                    axios.post('/api/subscriptions', data).then(response => {
                        window.location = '/subscription/' + response.data.type + '/website/' + response.data.websites_id;
                    }).catch(error => {
                        this.errors = error.response.data.errors
                        this.hasErrors = true
                    })
                }
            }
        },
        getProjectById(id) {
            for(let i = 0; i < this.togglProjects.length; i++) {
                if (this.togglProjects[i].value == id) {
                    return this.togglProjects[i]
                }
            }

            return {value: null, label: null};
        },

        validateForm() {
            this.validation.errors = [];
            this.validation.errorFields = []
            if (this.websitesId != null && this.websitesId == '') {
                this.validation.errors.push('Geef een website op')
                this.validation.errorFields.push('websitesId')
            }
            if (this.managersId != null && (this.managersId <= 0 || typeof this.managersId != 'number')) {
                this.validation.errors.push('Geef een manager op')
                this.validation.errorFields.push('managersId')
            }
            if (this.budgetType == 'monthly') {
                if (this.startDate != null && this.startDate == '') {
                    this.validation.errors.push('Geef een startdatum op')
                    this.validation.errorFields.push('startDate')
                }
            }
        },

        isLoading() {
            return !(this.subscriptionsTypes.length > 0 && this.togglProjects.length > 0 && this.users.length > 0)
        },

        loadProjects() {
            fetch('/api/togglprojects')
                .then(resp => resp.json())
                .then((json) => {
                    this.togglProjects = []
                    for (let s in json) {
                        let sub = json[s]
                        this.togglProjects.push({
                            'value': sub.toggl_project_id, 
                            'label': sub.project_name
                        })
                    }
                })
        },

        addNewProject() {
            if (this.newProjectName) {
                console.log({ 
                    name: this.newProjectName
                });
                
                axios.post('/api/toggl', {
                    name: this.newProjectName
                })
                .then(response => {
                    const newProject = response.data;

                    console.log(newProject); // Controleer de respons

                    // Voeg het nieuwe project toe aan de lijst
                    this.togglProjects.push({
                        value: newProject.id,
                        label: newProject.name
                    });

                    // Voeg het nieuwe project-ID toe aan de geselecteerde projecten
                    this.togglProjectId.push(newProject.id); 
                    
                    // Reset formulier velden
                    this.newProjectName = '';
                    this.showNewProjectForm = false;
                })
                .catch(error => {
                    console.error('Er is een fout opgetreden bij het toevoegen van het nieuwe project', error);
                });
            }
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.emitter.emit('set-title', 'Abonnement bewerken')

            this.subscriptionsId = this.$route.params.id
            fetch('/api/subscriptions/' + this.subscriptionsId)
                .then(resp => resp.json())
                .then((json) => {
                    this.websitesId = json.websites_id
                    this.linkbuildersId = json.linkbuilder_users_id
                    this.managersId = json.manager_users_id
                    this.budgetType = json.budget_type
                    this.websiteType = json.website_type
                    this.subscriptionsType = json.type
                    this.startDate = json.start_date.includes('T') ? json.start_date.split('T')[0] : ''
                    this.endDate = (json.end_date && json.end_date.includes('T')) ? json.end_date.split('T')[0] : ''
                    this.budget = json.budget
                    this.togglProjectId = json.toggl_projects

                    console.log(json)
                })

            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Abonnementen',
                    url: '/subscriptions'
                }
            ])

            this.editMode = true
        } else {
            this.emitter.emit('set-breadcrumbs', [
                {
                    label: 'Abonnementen',
                    url: '/subscriptions'
                }
            ])
            this.emitter.emit('set-title', 'Nieuw abonnement')
        }
        if (this.$route.params.websitesId) {
            this.websitesId = this.$route.params.websitesId;
        }

        if (this.$route.params.customerId) {
            this.customersId = this.$route.params.customerId;
        }

        fetch('/api/subscriptiontypes')
            .then(resp => resp.json())
            .then((json) => {
                this.subscriptionsTypes = []
                for (let s in json) {
                    let sub = json[s]
                    this.subscriptionsTypes.push({'value': s, 'label': sub})
                }
            })

        fetch('/api/websites')
            .then(resp => resp.json())
            .then((json) => {
                this.websites = []
                for (let w in json) {
                    let website = json[w]

                    if (!this.customersId || (this.customersId == website.customers_id)) {
                        this.websites.push({
                            'label': website.name,
                            'value': website.id,
                            'icon': website.favicon
                        })

                        if (this.editMode && website.id == this.websitesId) {
                            let type = this.subscriptionsType

                            switch (this.subscriptionsType) {
                                case 'lb':
                                    type = 'Linkbuilding'
                                    break
                                case 'seo':
                                    type = 'SEO'
                                    break
                                case 'sea':
                                    type = 'SEA'
                                    break
                                case 'mediabudget':
                                case 'media':
                                    type = 'Mediabudget'
                                    break
                                case 'migrate':
                                    type = 'Migratie'
                                    break;
                            }
                            this.emitter.emit('set-title', 'Abonnement bewerken: ' + type + ' - ' + website.name)
                            this.selectedWebsite = website
                        }
                    }
                }

                if (this.websites.length == 1) {
                    this.websitesId = this.websites[0].value
                }
            })

        fetch('/api/users')
            .then(resp => resp.json())
            .then((json) => {
                this.users = []
                for (let u in json) {
                    let user = json[u]
                    this.users.push({
                        'label': user.name,
                        'value': user.id
                    })
                }
            })

        this.loadProjects()
    }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7) url(../../../img/loading.gif) no-repeat center;
        background-size: 200px;
        z-index: 9999;
    }
</style>
